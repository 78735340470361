// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'video.js/dist/video-js.min.css';

@import 'd3-mitch-tree/dist/css/d3-mitch-tree.min.css';
@import 'd3-mitch-tree/dist/css/d3-mitch-tree-theme-default.min.css';
//@import 'video.js/dist/video-js.min.css';
@import "~video.js/dist/video-js";
//@import "boxicons";
@import '~jquery-ui/themes/base/all.css';

